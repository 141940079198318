import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/blogPost.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "pegasys-successfully-concludes-its-seed-round-with-big-names-from-the-ecosystem",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#pegasys-successfully-concludes-its-seed-round-with-big-names-from-the-ecosystem",
        "aria-label": "pegasys successfully concludes its seed round with big names from the ecosystem permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Pegasys successfully concludes its Seed Round with big names from the ecosystem`}</h1>
    <p>{`PegasysDEX, the first and leading AMM on Syscoin NEVM, is glad to announce that they have successfully completed the first seed round. The investments raised are from companies all over the world, such as Syscoin Foundation, BlockDesk Ventures, Cogitent Ventures, Barrah Investments, LucidBlue Ventures, Flow Investments, in addition to multiple strategic angel investors.
The plans to use the capital will go towards the improvement and extension of the protocol as well as establishing a firm foundation for the project.`}</p>
    <h2 {...{
      "id": "about-the-investors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#about-the-investors",
        "aria-label": "about the investors permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{` About the investors  `}</strong></h2>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1180px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/dfa792828bc9a1d864eb04fda0423256/c83ae/investorsLogos.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "57.666666666666664%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB0klEQVQoz3WT0W/TMBDG8///AVDEG68DaVNBCMoLj5MGFAml6worbdc6SdOkSZo6Tmz/UJMGstKddDrL9n13n/2dA2Ctrf1gpVIoKTFa07XunW5s16057WbreZKw8TwizyPZbJB5jjXmEXA3nloNaNruioLI9wmFIFytWC+XBIsF2zBEV1VdIA6CmoXtdNjt1DnWbYLRZPGGyFsRiRXbICBdr9mnKSrP2fo+GyHQSvEvzz7q3okzyzywqMqSS0uWFQR+jPfg89N1+XZ9zcQd8f3mBnc4JPR81jIj2EuWO4PILSI3yKp5FmfqWV59stwLuHuAXwLGC8syMIzdEVevL/jQv2Tw9orLiwvE7Dfz+J61ClmWCZNkzm0UUOi/lJtFoQ1KW4yukIWirMqaah5HzCd3zCZ3qCzBW2aIWcRkXOH+MIRBcXj9GqambEwD+Hma8+ZLynAm+TiKeT/e8HUqiITgZa/H82c9oKLfN7zr5wwGKb0XCa7byKvFOcqm2ai0pdQWbS0VkCRp/QmFlFRHXSplkfKQrI/e5Lcf5DytKcs+TcjT9D8NNh3BoUYj0Qa0pnyq+q6mVFEgd7tad+funJse55za20NjDFVZ1vGpUTudlD9kGZ1frap8iwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Great investors flying along with PegasysDEX",
              "title": "Great investors flying along with PegasysDEX",
              "src": "/static/dfa792828bc9a1d864eb04fda0423256/c83ae/investorsLogos.png",
              "srcSet": ["/static/dfa792828bc9a1d864eb04fda0423256/5a46d/investorsLogos.png 300w", "/static/dfa792828bc9a1d864eb04fda0423256/0a47e/investorsLogos.png 600w", "/static/dfa792828bc9a1d864eb04fda0423256/c83ae/investorsLogos.png 1180w"],
              "sizes": "(max-width: 1180px) 100vw, 1180px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Great investors flying along with PegasysDEX`}</figcaption>{`
  `}</figure></p>
    <h2 {...{
      "id": "syscoin-foundation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#syscoin-foundation",
        "aria-label": "syscoin foundation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{` Syscoin Foundation `}</strong></h2>
    <p><a parentName="p" {...{
        "href": "https://syscoin.org/"
      }}>{`Syscoin`}</a>{` is ushering in the next step in the evolution of blockchain technology, providing Bitcoin’s proven security and Ethereum’s Turing-complete programmability elevated to true scalability via ZK-Rollups and other Layer 2 technologies.
Working alongside Syscoin Foundation was essential since they are the most strategic investors, leading the raise and having a pretty comprehensive role: intros, partnerships, exposure, community, and general operational/business support.`}</p>
    <h2 {...{
      "id": "angels-investors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#angels-investors",
        "aria-label": "angels investors permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{` Angels Investors `}</strong></h2>
    <p>{`We’ve had key-members from Syscoin, Blockchain Foundry and the broader DeFi/Cybersecurity space join us as angel investors. The one and only Jag Sidhu, lead developer and president at Syscoin Foundation was the main angel in the round, accompanied by big names from the community such as Big Poppa & Willy Ko. Zak Cole, Co-founder at Whiteblock and current CTO of Slingshot, also joined the round, bringing the battle-hardened DeFi experience to the table.`}</p>
    <h2 {...{
      "id": "blockdesk-ventures",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#blockdesk-ventures",
        "aria-label": "blockdesk ventures permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{` BlockDesk Ventures `}</strong></h2>
    <p><a parentName="p" {...{
        "href": "https://blockdeskventures.io/"
      }}>{`BlockDesk Ventures`}</a>{` is a company of strategic investors who have a passion for innovative Blockchain projects. Their aim is to further progress the vision of decentralization and freedom in financial operations by powering the most innovative projects in the industry.
As one of the main VCs on the Syscoin ecosystem, they are also backing Luxy and NFT projects in the network such as SysPunks, providing good coherence to the projects and paving the way for future collaborations.`}</p>
    <h2 {...{
      "id": "barrah-investments",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#barrah-investments",
        "aria-label": "barrah investments permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{` Barrah Investments `}</strong></h2>
    <p><a parentName="p" {...{
        "href": "http://www.barrah.com.br/"
      }}>{`Barrah`}</a>{` is a Brazilian venture capital trust that supports a lot of innovative projects, bringing the best of entrepreneurship expertise to their investments. With a very solid Web2 portfolio (Rappi, BossaBox, Pipefy and many others), Barrah is the key to unlocking the Brazilian community inside Pegasys and Syscoin, but also expanding our branding and adoption inside Brazil with real-world connections and events. Barrah, in a partnership with GDP Tech and `}<a parentName="p" {...{
        "href": "https://www.almadao.xyz/"
      }}>{`AlmaDAO`}</a>{` is also allocating physical working space to the Pegasys team and contributors inside one of the largest technology hub in Brazil.`}</p>
    <h2 {...{
      "id": "cogitent-ventures",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#cogitent-ventures",
        "aria-label": "cogitent ventures permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{` Cogitent Ventures `}</strong></h2>
    <p><a parentName="p" {...{
        "href": "https://cogitent.ventures/"
      }}>{`Cogitent Ventures`}</a>{` is an organization created to discover, advise and invest in novel technologies and business models for cognitive computing. They are strategic partners and have been accumulating great partnerships with web3 projects.`}</p>
    <h2 {...{
      "id": "flow-investments",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#flow-investments",
        "aria-label": "flow investments permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{` Flow Investments `}</strong></h2>
    <p><a parentName="p" {...{
        "href": "https://mobile.twitter.com/flowinvestment"
      }}>{`Flow Investments`}</a>{` is a global firm focused on Spanish spoken Community and managed by influencers and KOLS in the sector. Since a considerable part of our community is Spanish, Flow provides real value to Pegasys by leveraging influencers, contacts and partnerships to expand in this fast-expanding hispanic market.`}</p>
    <h2 {...{
      "id": "lucidblue-ventures",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#lucidblue-ventures",
        "aria-label": "lucidblue ventures permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{` LucidBlue Ventures `}</strong></h2>
    <p><a parentName="p" {...{
        "href": "https://www.lucidblueventures.com/"
      }}>{`LucidBlue Ventures`}</a>{` is a leading investment firm and venture-building platform focused on capitalizing on the disruptive impact of blockchain, digital assets, and digital finance.​ They are lead by Charlie Hu, a giant name in the Web3.0 space and former China Head of Polygon, who is now more and more involved with Syscoin as an official advisor.
​They have a strong focus on the fundamental infrastructure for Web 3.0 that will enable the emerging DeFi, NFT, GameFi, SocialFi, and Metaverse industries. DeFi and NFT technologies represent the financialization layer for Web 3.0 applications and the emerging Metaverse, allowing digital assets to turbo-charge the development of new digital economies driven on innovative tokenomics business models. Charlie is very well connected in the space and is bringing in more and more partners every week.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      